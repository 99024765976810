.wrap{
  padding: 10px 15px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.leftWrap,.rightWrap{
  flex: 0 0 40%;
  max-width: 460px;
}
.centerWrap{
  flex: 1 1 0;
  margin: 0 10px;
  height: 100%;
}