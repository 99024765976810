.wrap{
    height: calc(100vh - 60px);
    width: 100%;
    display: flex;
    flex-direction: column;
    overscroll-behavior: none;
}
.textFilterWrap{
    display: flex;
    justify-content: space-between;
}

.textFilterSelect{
    width: 100px;
    margin-left: 10px;
}
.filterWrap{
    flex: 0 0 min-content;
}
.listWrap{
    flex: 1 1 0;
    overflow: hidden auto;
}

.btnWrap{
    margin: 10px 0 5px;
    display: flex;
    max-width: 460px;
    justify-content: space-between;
}

@media screen and (min-width: 0) {
    .filterWrap{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px 15px;
    }
}

@media screen and (min-width: 1100px) {
    .filterWrap{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1300px) {
    .filterWrap{
        grid-template-columns: 1fr 1fr 1fr;
    }
}