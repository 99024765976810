.wrap{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
}
.content{
    flex: 1 1 0;
    overflow: hidden auto;
    line-height: 1.5;
}
.info{
    margin: 8px 0;
}